<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
        v-if="post"
        @click:outside="hide"
        :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card class="fill-height d-flex flex-column">
        <v-card-title class="justify-center">
          <img style="width: 200px" src="https://www.rutesheim.de/site/Rutesheim-Internet-2021/resourceCached/10.3.4.1/img/logo.png">
          <v-spacer/>
          <v-btn icon @click="dialog=false"><v-icon>mdi-close-thick</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <h2> {{ post.title }}</h2>
          <div class="text-h6">
            {{post.excerpt}}
          </div>
          <p v-if="post.type === 'message'" class="date mb-1">{{post.publishingDate | formatLocaleDate}}</p>
          <template v-if="post.type === 'event'">
            <p class="date mb-1">Beginn: {{post.startDate | formatLocaleDateTime}}</p>
            <p class="date mb-1">Ende: {{post.endDate | formatLocaleDateTime}}</p>
          </template>
          <v-img v-if="post.image" max-width="150" class="my-4" v-bind:src="getFileUrl(post.image.path)"/>
          <div class="content" v-html="post.content"></div>
          <p v-if="company">Dieser Post wurde erstellt von {{company.name}}</p>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="hide"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {getFileUrl} from "@/utils/common";

export default {
  name: "PostDialog",
  data() {
    return {
      post: undefined,
      dialog: false,
      company: undefined,
    }
  },
  methods: {
    getFileUrl,
    show(post, company) {
      console.log("Show post")
      this.post = post
      this.company = company
      this.dialog = true
    },
    hide() {
      this.post = undefined
      this.dialog = false
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  ::v-deep p {
    margin-bottom: 0;
  }
}
</style>
