<template>
  <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      hide-on-leave
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      v-model="showDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-on="on"
          v-bind="attrs"
      ><v-icon>mdi-map</v-icon></v-btn>
    </template>
    <template>
      <div>
        <v-card class="fill-height d-flex flex-column">
          <v-card-title class="justify-center">
            <img style="width: 200px" src="https://www.rutesheim.de/site/Rutesheim-Internet-2021/resourceCached/10.3.4.1/img/logo.png">
            <v-spacer/>
            <v-btn icon @click="showDialog=false"><v-icon>mdi-close-thick</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <h2>Karte</h2>
            <google-maps :companies="companies" :categories="categories" :highlighted="highlighted" @click:company="$emit('click:company', $event)"/>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
                color="info"
                class="mx-auto px-4"
                @click="showDialog=false"
            >
              Schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </template>
  </v-dialog>
</template>
<script>
import GoogleMaps from "@/components/GoogleMaps.vue";

export default {
  name: 'MapsDialog',
  components: {GoogleMaps},
  props: {
    companies: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: false,
    },
    lat: {
      type: Number,
      default: 48.808220,
    },
    lng: {
      type: Number,
      default: 8.945020,
    },
    zoom: {
      type: Number,
      default: 13,
    },
    highlighted: {
      required: true
    }
  },
  data () {
    return {
      showDialog: false,
    }
  },
  methods: {
  },
  computed: {
  },
}
</script>
<style scoped>
</style>
