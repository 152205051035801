<template>
  <v-row>
    <v-col cols="12">
      <v-card class="mx-auto" width="300">
        <menu-item name="bla" link="test" icon="huhu" :auth="['admin', 'manager']"/>
        <menu-item name="Cluster" link="test" icon="huhu" />
        <menu-item items="clusters" v>
            <v-menu name="Benuter" link="testx"></v-menu>
        </menu-item>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-group prepend-icon="account_circle" value="true">
            <template v-slot:activator>
              <v-list-item-title>Users</v-list-item-title>
            </template>
            <v-list-group no-action sub-group value="true">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Admin</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="(admin, i) in admins" :key="i" link>
                <v-list-item-title v-text="admin[0]"></v-list-item-title>
                <v-list-item-icon>
                  <v-icon v-text="admin[1]"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
            <v-list-group sub-group no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Actions</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="(crud, i) in cruds" :key="i">
                <v-list-item-title v-text="crud[0]"></v-list-item-title>
                <v-list-item-action>
                  <v-icon v-text="crud[1]"></v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "HelloWorld",
  data: () => ({
    admins: [
      ["Management", "people_outline"],
      ["Settings", "settings"],
    ],
    cruds: [
      ["Create", "add"],
    ],
  }),
  methods: {}
};
</script>
