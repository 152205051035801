<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Tag</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="tag.name"
                      label="Title"
                      required
                      :rules="requiredRule('Name')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Schließen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveTag"
          >
            {{ tag.id ? 'Speichern' : 'Hinzufügen'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>
<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/admin/Snackbar'
import {mapState} from "vuex";

export default {
  name: 'TagEditDialog',
  components: { Snackbar, ConfirmDialog },
  data: () => ({
    categories: [],
    showDialog: false,
    tag: {},
    snackbarSuccess: {
      text: 'Tag erfolgreich gespeichert',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    menu: false,
  }),
  methods: {
    show (tag, clusterId) {
      if(tag)  {
        this.tag = { ...tag }
        delete this.tag.category
      } else {
        this.tag = { cluster: clusterId }
      }
      this.showDialog = true
    },
    close () {
      this.showDialog = false
      this.tag = {}
      this.$refs.userForm.resetValidation()
    },
    async saveTag () {
      if (!this.$refs.userForm.validate()) {
        return
      }
      if (this.tag.id) {
        APIService.updateTag(this.tag).then((response) => {
          console.log("Update Response", response, this.tags)
          this.$emit('updated', this.tag)
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      } else {
        console.log("Tag", this.tag)
          APIService.createTag(this.tag).then((tag) => {
          console.log("Save Response", tag, this.tags)
          this.$emit('saved', tag)
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      }
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    }
  },
  mounted() {
    APIService.getPostCategories().then((categories) => {
      this.categories = this.user.role === 'user'? categories.filter(category => category.type === 'company') : categories
    })
  }
}
</script>

<style scoped>

</style>
