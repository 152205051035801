<template>
  <div v-if="showResult" class="text-center mt-6">
    <div v-if="verifySuccess">
      <p >
        Ihre E-Mail wurde erfolgreich überprüft. Sie können sich jetzt mit mit Ihren Zugangsdaten anmelden.
      </p>
      <p>
        <router-link :to="'/login'">Login</router-link>
      </p>
    </div>
    <div v-else>
      <p >
        Die überprüfung ihrer E-Mail ist fehlgeschlagen. Bitte wenden sie sich an useren Support.
      </p>
      <p>
        <router-link :to="'/'">Weiter</router-link>
      </p>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import APIService from "@/services/APIService";
export default {
  name: 'Login',
  data () {
    return {
      showResult: false,
      verifySuccess: false,
    }
  },
  methods: {
    ...mapMutations([]),
    ...mapGetters(['isLoggedIn']),
    ...mapActions(['login', 'logout']),
  },
  computed: {
    ...mapState[{
      refreshToken: (state) => state?.user?.tokens?.refresh
    }]
  },
  mounted() {
    APIService.verifyEmail(this.$route.query.token).then(() => {
      this.showResult = true
      this.verifySuccess = true
    }).catch(() => {
      this.showResult = true
      this.verifySuccess = false
    })
  }
}
</script>

<style scoped>

</style>
