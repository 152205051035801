<template>
  <div>
    <div style="min-height: 450px; max-width: 750px" id="map"></div>
    <company-info-dialog ref="companyInfoDialog"/>
  </div>
</template>

<script>
import CompanyInfoDialog from "@/components/CompanyInfoDialog";
import {getFileUrl} from "@/utils/common";
export default {
  name: "GoogleMaps",
  components: {CompanyInfoDialog},
  props: {
    companies: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: false,
    },
    lat: {
      type: Number,
      default: 48.808220,
    },
    lng: {
      type: Number,
      default: 8.945020,
    },
    zoom: {
      type: Number,
      default: 13,
    },
    highlighted: {
      required: true
    }
  },
  data() {
    return {
      map: undefined,
      markers: [],
    }
  },
  methods: {
    isHighlighted(id) {
      return this.highlighted === id
    },
    updateMap() {
      if (this.markers) {
        let i;
        for (i in this.markers) {
          this.markers[i].setMap(null);
        }
      }
      this.companies.forEach((company) => {
        const map = this.map
        const category = this.categories.find((category) => category.id === company.primaryCategory)
        if(category) {
          console.log("Category", category)
        }
        if(company.geometry) {
          // eslint-disable-next-line no-undef
          const marker = new google.maps.Marker({
            position: company.geometry,
            map,
            title: company.name,
            ...( this.isHighlighted(company.id) ?
                { icon: 'http://maps.google.com/mapfiles/ms/icons/blue.png' } :
                category?.icon ? { icon:  getFileUrl(category.icon.path) } : {}),
            zIndex: this.isHighlighted(company.id) ? 1 : 0
          });
          marker.addListener("click", () => {
            console.log("You have clicked me!", company)
            this.$emit('click:company',company)
            // this.$refs.companyInfoDialog.show(company)
          });
          this.markers.push(marker)
        }
      })
    }
  },
  mounted() {
    const center = { lat: this.lat, lng: this.lng };
    // eslint-disable-next-line no-undef
    this.map = new google.maps.Map(document.getElementById("map"), {
      zoom: this.zoom,
      center,
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        }
      ]
    });
    this.updateMap();
  },
  watch: {
    companies: function() {
      this.updateMap()
    },
    highlighted: function() {
      this.updateMap()
    },
  }
}
</script>

<style scoped>
</style>
