<template>
  <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      hide-on-leave
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      v-model="showDialog"
  >
      <template v-slot:activator="{ on, attrs }">
        <a
            v-on="on"
            class="mx-2"
            v-bind="attrs"
        >Login</a>
      </template>
      <template>
        {{token}}
        <v-card class="fill-height d-flex flex-column">
          <v-card-title class="justify-center">
            <img style="width: 200px" src="https://www.rutesheim.de/site/Rutesheim-Internet-2021/resourceCached/10.3.4.1/img/logo.png">
            <v-spacer/>
            <v-btn icon @click="showDialog=false"><v-icon>mdi-close-thick</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <h2>Passwort zurücksetzen</h2>
            <p>Bitte geben sie ein neues Passwort ein</p>
            <v-form value>
              <v-col cols="12">
                <v-text-field
                    label="Password"
                    prepend-icon="mdi-lock"
                    :type="showLogin ? 'text' : 'password'"
                    :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showLogin=!showLogin"
                    v-model="password"
                    :rules="passwordRules"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Passwort-Wiederholung"
                    prepend-icon="mdi-lock"
                    :type="showLogin ? 'text' : 'password'"
                    :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showLogin=!showLogin"
                    v-model="passwordConfirmation"
                    :rules="passwordConfirmationRules"

                />
              </v-col>
            </v-form>
          </v-card-text>
          <v-spacer/>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
                color="info"
                class="mx-auto px-4"
                @click="doResetPassword"
            >
              Passwort zurücksetzen
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import APIService from "@/services/APIService";
import {snackbar} from "@/utils/common";
export default {
  name: 'LoginDialog',
  data () {
    return {
      password: '',
      passwordConfirmation: '',
      showDialog: false,
      showLogin: false,
      showPassword: false,
      token: '',
      passwordRules: [
        value => !!value || 'Eingabe erforderlich',
        value => (value  && value.length >= 8) || 'Das Passwort muss mindestens 8 Zeichen lang sein.',
        value => (value && !!value.match(/\d/) && !!value.match(/[a-zA-Z]/)) || 'Das Passwort muss mindestens einen Buchstaben und eine Zahl enthalten',
      ],
      passwordConfirmationRules: [
        value => {
          console.log("compare", this.password, value )
          return !this.password || value === this.password || 'Die eingegebenen Passwörter stimmen nicht überein'
        }
      ]
    }
  },
  methods: {
    ...mapMutations([]),
    ...mapGetters(['isLoggedIn']),
    ...mapActions(['login', 'logout']),
    show(token) {
      this.token = token;
      this.showDialog = true;
    },
    doResetPassword() {
      APIService.resetPassword(this.token, this.password).then(() => {
        snackbar.success('Das Passwort wurde erfolgreich zurückgesetzt');
        this.showDialog = false;
      })
      this.login({email: this.email, password: this.password}).then(() => {
        this.$router.push({name: 'admin'})
        this.$emit('success')
      }).catch(() => {
        this.$emit('fail')
        this.logout()
      })
    }
  },
  computed: {
    ...mapState[{
      refreshToken: (state) => state?.user?.tokens?.refresh
    }]
  }
}
</script>

<style scoped>

</style>
