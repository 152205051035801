<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ label }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item :key="item[itemValue]" v-for="(item,index) in items">
      <v-list-item-action>
        <v-switch
            color="purple"
            v-model="switchStates[index]"
            @change="switchItem({ $event, item })"
        />
      </v-list-item-action>
      <v-list-item-title>{{ item[itemText] }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: "MultiSwitch",
  props: {
    label: {
      type: String
    },
    value: {
      type: Array,
    },
    items: {
      type: Array,
      required: true
    },
    'itemText': {
      type: String,
      required: true,
    },
    'itemValue': {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      switchStates: [],
      selectedItems: [],
    }
  },
  methods: {
    switchItem({$event, item}) {
      if ($event) {
        this.selectedItems = [...this.selectedItems, item]
      } else {
        this.selectedItems = this.selectedItems.filter(i => item.id !== i.id)
      }
      this.$emit('input', Object.keys(this.selectedItems).map(key => this.selectedItems[key]));
    },
  },
  watch: {
    value: function () {
      this.selectedItems = [...this.value]
      this.switchStates = this.items.map((item) =>  this.selectedItems.includes(item) )
    },
  }
}
</script>

<style scoped>

</style>