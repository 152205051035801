<template>
    <v-app>
        <v-main>
            <router-view/>
        </v-main>
        <v-footer
                color="primary lighten-1"
                padless
        >
            <v-row
                    justify="center"
                    no-gutters
            >
                <v-btn
                        v-for="link in links"
                        :key="link.label"
                        color="white"
                        text
                        small
                        rounded
                        class="my-1"
                        @click="openWindow(link.url)"
                >
                    {{ link.label }}
                </v-btn>
            </v-row>
        </v-footer>
        <my-snack-bar ref="mySnackBar"></my-snack-bar>
        <reset-password-dialog ref="resetPasswordDialog"></reset-password-dialog>
    </v-app>
</template>
<script>

import MySnackBar from "@/components/MySnackBar.vue";
import {eventBus} from "@/main";
import ResetPasswordDialog from "@/components/dialogs/ResetPasswordDialog.vue";

export default {
    name: 'App',
    components: {MySnackBar, ResetPasswordDialog},

    data: () => ({
        links: [
            {label: 'Impresssum', url: 'https://regiozentral.s3m-werkstatt.de/impressum/'},
            {label: 'Datenschutzerklärung', url: 'https://regiozentral.s3m-werkstatt.de/datenschutz/'},
        ]
    }),
    methods: {
        openWindow(url) {
            window.open(url, '_blank');
        }
    },
    mounted() {
        eventBus.$on('snackbar-success', (message) => {
            this.$refs.mySnackBar.success(message);
        })
        eventBus.$on('snackbar-error', (message) => {
            this.$refs.mySnackBar.error(message);
        })
        eventBus.$on('show-reset-password-dialog', (token) => {
            console.log("Event fired", token);
            this.$router.replace({'query': null});
            this.$refs.resetPasswordDialog.show(token)
        })
    }
};
</script>
<style lang="scss" scoped>
.v-btn {
  border-style: none;
}
</style>