<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Kategorien</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <template v-for="selectableCategory in getSelectableCategories()">
      <v-list-item class="parent-category" :key="selectableCategory.id" >
        <v-list-item-action>
          <v-switch
              color="purple"
              v-model="switchStates[selectableCategory.id]"
              @change="switchItem({ $event, category:  selectableCategory })"
          />
        </v-list-item-action>
        <v-list-item-title>{{  selectableCategory.name }}</v-list-item-title>
      </v-list-item>
    </template>
    <template v-for="parentCategory in parentCategories">
      <v-list-item class="parent-category" :key="parentCategory.id" >
        <v-list-item-content>
          {{parentCategory.name}}
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          class="child-category"
          :key="childCategory.id"
          v-for="childCategory in getSelectableCategories(parentCategory.id)"
      >
        <v-list-item-action>
          <v-switch
              color="purple"
              v-model="switchStates[childCategory.id]"
              @change="switchItem({ $event, category: childCategory })"
          />
        </v-list-item-action>
        <v-list-item-title>{{  childCategory.name }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>
<script>
export default {
  name: "CategorySwitch",
  props: {

    value: {
      type: Array,
    },
    items: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      switchStates: {},
      selectedItems: [],
    }
  },
  computed: {
    parentCategories() {
      return this.items.filter((cat) => !cat.parent && cat.hasChildren);
    },
  },
  methods: {
    getSelectableCategories(parentId = null) {
      const categoryFilter = parentId ? (cat) => cat.parent === parentId : (cat) => !cat.hasChildren && !cat.parent
      return this.items.filter(categoryFilter)
    },
    switchItem({$event, category}) {
      if ($event) {
        this.selectedItems = [...this.selectedItems, category]
      } else {
        this.selectedItems = this.selectedItems.filter(i => category.id !== i.id)
      }
      this.$emit('input', Object.keys(this.selectedItems).map(key => this.selectedItems[key]));
    },
  },
  watch: {
    value: function () {
      this.selectedItems = [...this.value]
      this.switchStates = this.items.reduce((states, item) => {
        states[item.id] = this.selectedItems.includes(item);
        return states
      }, {})
    },
  }
}
</script>

<style scoped>

</style>