<template>
  <div>
    <h1>Mitglieder<span v-if="cluster"> ({{cluster.name}})</span></h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="6" class="order-last order-md-first">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="addClusterMembers"
            >
              <v-icon left>mdi-plus</v-icon>
              Mitglied
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="members"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
          :hide-default-footer="members.length < 5"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              color="primary"
              @click="removeMember(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <add-cluster-member-dialog ref="addClusterMemberDialog" @input="memberAdded"/>
  </div>
</template>

<script>
import APIService from "@/services/APIService";
import AddClusterMemberDialog from "@/components/admin/AddClusterMemberDialog";
export default {
  name: "ClusterMembers",
  components: {
    AddClusterMemberDialog
  },
  props: ['clusterId'],
  data() {
    return {
      members: [],
      cluster: null,
      search: '',
      headers: [
        {
          text: 'Title',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Telefon', value: 'contact.phone' },
        { text: 'Email', value: 'contact.email' },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
    }
  },
  methods: {
    async removeMember(item) {
      await APIService.removeClusterMember(this.clusterId, item.id)
      this.members = await APIService.getClusterMembers(this.$route.params.clusterId)
    },
    addClusterMembers() {
      this.$refs.addClusterMemberDialog.show(this.clusterId, this.members)
    },
    async memberAdded() {
      this.members = await APIService.getClusterMembers(this.clusterId)
    }
  },
  async beforeRouteEnter(to, from ,next) {
    const clusterId = to.params.clusterId
    const result = await fetchAll(clusterId)
    next(vm => {
      vm.cluster = result[0]
      vm.members = result[1]
    })
  },
  async beforeRouteUpdate(to, from, next) {
    const result = await fetchAll(to.params.clusterId)
    this.cluster = result[0]
    this.members = result[1]
    next()
  }
}
async function fetchAll(clusterId) {
  return Promise.all( [
    APIService.getCluster(clusterId),
    APIService.getClusterMembers(clusterId)
  ])
}
</script>

<style scoped>
</style>
