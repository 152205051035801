import Vue from 'vue'
import Vuex from 'vuex'
import APIService from "@/services/APIService";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    posts: [],
    user: null,
  },
  getters: {
    isLoggedIn(state) {
      return state.user !== null
    },
    accessToken(state) {
      return state?.user?.tokens?.access?.token
    },
    refreshToken(state) {
      return state?.user?.tokens?.refresh?.token
    },
    userRole(state) {
      return state?.user?.role
    }
  },
  mutations: {
    updatePosts(state, posts) {
      state.posts = posts
    },
    updateUser(state, user) {
      state.user = user
    },
    updateAccessTokens(state, tokens) {
      state.user = { ...state.user, tokens }
    },
    logout(state) {
      state.user = null
    }

  },
  actions: {
    refreshToken({commit,state}) {
      const token = state?.user?.tokens?.refresh?.token ?? 'invalid'
      return APIService.refreshToken(token).then(tokens => {
        commit('updateAccessTokens', tokens)
        return tokens
      })
    },
    fetchPosts({commit, categoryId}) {
        APIService.getPosts(categoryId).then((posts) => {
          commit('updatePosts', posts)
        })
    },
    login({ commit }, { email, password }) {
       return APIService.login(email, password).then(data => {
         const userData = {...data.user, tokens: data.tokens }
         commit('updateUser', userData)
       })
    },
    async logout({commit, getters}) {
      if(getters.isLoggedIn) {
        await APIService.logout(getters.refreshToken)
        commit('logout')
      }
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
