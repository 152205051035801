<template>
  <div>News for Cluster {{clusterId}}</div>
</template>

<script>
export default {
  name: "NewsList",
  props: ['clusterId']
}
</script>

<style scoped>

</style>
