<template>
  <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      hide-on-leave
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      v-model="show"
  >
      <template v-slot:activator="{ on, attrs }">
        <a
            v-on="on"
            class="mx-2"
            v-bind="attrs"
        >Login</a>
      </template>
      <template v-if="show">
        <v-card class="fill-height d-flex flex-column">
          <v-card-title class="justify-center">
            <img style="width: 200px" src="https://www.rutesheim.de/site/Rutesheim-Internet-2021/resourceCached/10.3.4.1/img/logo.png">
            <v-spacer/>
            <v-btn icon @click="show=false"><v-icon>mdi-close-thick</v-icon></v-btn>
          </v-card-title>
          <v-card-text v-if="!showResetDialog">
            <h2>Login</h2>
            <p>Bitte geben Sie hier Ihre Zugangsdaten ein.</p>
             <p>Sollten Sie noch keinen Zugang haben bitte zunächst registrieren.</p>
            <v-form value>
              <v-text-field
                  label="E-Mail"
                  prepend-icon="mdi-account-circle"
                  v-model="email"
                  @keydown.enter.exact.prevent="doLogin"
              />
              <v-text-field
                  label="Password"
                  prepend-icon="mdi-lock"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword=!showPassword"
                  v-model="password"
                  @keydown.enter.exact.prevent="doLogin"
              />
            </v-form>
            <p><a @click.prevent="showResetDialog=true">Haben Sie Ihr Passwort vergessen?</a></p>
          </v-card-text>
          <v-card-text v-else>
            <p>Bitte geben Sie Ihren Benutzernamen oder deine E-Mail Adresse ein.</p>
              <p>Sie werden per E-Mail einen Link erhalten, über den Sie ein neues Passwort vergeben können.</p>
            <v-form value>
              <v-text-field
                  label="E-Mail"
                  prepend-icon="mdi-account-circle"
                  v-model="email"
                  @keydown.enter.exact.prevent="requestPassword"
              />
            </v-form>
          </v-card-text>

          <v-spacer/>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
                v-if="!showResetDialog"
                :disabled="!(email && password)"
                color="info"
                class="mx-auto px-4"
                @click="doLogin"
            >
              Login
            </v-btn>
            <v-btn
                v-else
                :disabled="!isEmailValid(email)"
                color="info"
                class="mx-auto px-4"
                @click="requestPassword"
            >
              Neues Passwort anfordern
            </v-btn>
          </v-card-actions>
        </v-card>

      </template>
    </v-dialog>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import APIService from "@/services/APIService";
import {isEmailValid} from "@/utils/common";
import {snackbar} from "@/utils/common";

export default {
  name: 'LoginDialog',
  data () {
    return {
      email: '',
      password: '',
      show: false,
      showPassword: false,
      showResetDialog: false,
    }
  },
  methods: {
    ...mapMutations([]),
    ...mapGetters(['isLoggedIn']),
    ...mapActions(['login', 'logout']),
    isEmailValid,
    doLogin() {
      this.login({email: this.email, password: this.password}).then(() => {
        this.$router.push({name: 'admin'})
      }).catch(() => {
        snackbar.error('Falscher Benutzername oder Passwort')
        this.logout()
      })
    },
    requestPassword() {
      APIService.forgotPassword(this.email).then(() => {
        snackbar.success('Eine E-Mail zum zurücksetzen Ihres Passwortes wurde versendet')
            this.show = false
      }).catch(e => {
        if(e.response.status === 404) {
          snackbar.fail('E-Mail nicht gefunden')
        } else {
          snackbar.fail('Fehler beim versenden der E-Mail')
        }
      })
    },
  },
  computed: {
    ...mapState[{
      refreshToken: (state) => state?.user?.tokens?.refresh
    }]
  },
  watch: {
    show(newValue) {
      if(!newValue) {
        this.showResetDialog = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
