<template>
  <div>
    <h1>Kategorien<span v-if="cluster"> ({{cluster.name}})</span></h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createCategory"
            >
              <v-icon left>mdi-plus</v-icon>
              Kategorie
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="categories"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
          :hide-default-footer="categories.length < 5"
      >
        <template v-slot:item.parent="{ item }">
          {{getCategoryName(item.parent)}}
        </template>
        <template v-slot:item.types="{ item }">
          {{ getTypeNames(item.types) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editCategory(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteCategory(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <category-edit-dialog ref="categoryEditDialog" @changed="updateCategoryList"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import CategoryEditDialog from '@/components/admin/CategoryEditDialog'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import {categoryTypes} from "@/utils/common";

export default {
  name: "CategoryList",
  components: {
    CategoryEditDialog,
    ConfirmDialog
  },
  props: ['clusterId'],
  data() {
    return {
      categories: [],
      cluster: {},
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
          width: 500,
        },
        { text: 'Elternkategorie', value: 'parent' },
        { text: 'Anwendbar für', value: 'types' },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      search: '',
    }
  },
  methods: {
    createCategory () {
      this.$refs.categoryEditDialog.show(undefined, this.clusterId, this.categories)
    },
    async editCategory(category) {
      const _category = await APIService.getCategory(category.id)
      console.log("Item", category, _category)
      this.$refs.categoryEditDialog.show(_category, undefined, this.categories)
    },
    deleteCategory: function (category) {
      this.$refs.confirmDelete.show({
        title: 'Category entfernen',
        text: `Wollen sie den Category <b>${category.name}</b> entfernen?`,
        confirm: 'entfernen'
      }).then(() => {
        APIService.deleteCategory(category.id).then(() => {
          this.categories = this.categories.filter((p) => p.id !== category.id)
          console.log('Item deleted')
        })
      })
    },
    async updateCategoryList() {
      this.categories = await APIService.getClusterCategories(this.clusterId)
    },
    getCategoryName(id) {
      return this.categories.find(cat => cat.id === id)?.name ?? ""
    },
    getTypeNames(types) {
      return types.map((typeId) =>  categoryTypes.find( (type) => type.id === typeId).label).join(", ")
    }
  },
  async beforeRouteEnter(to, from ,next) {
    const clusterId = to.params.clusterId
    let cluster, categories
    if(clusterId) {
      [cluster, categories] = await fetchAll(clusterId)

    } else {
      categories = await APIService.getCategories()
    }
    next((vm) => {
      vm.categories  = categories
      vm.cluster = cluster
    })
  },
  async beforeRouteUpdate(to, from, next) {
    const clusterId = to.params.clusterId
    const [cluster, categories] = await fetchAll(clusterId)
    this.cluster = cluster
    this.categories = categories
    next()
  },
}
async function fetchAll(clusterId) {
  return Promise.all( [
    APIService.getCluster(clusterId),
    APIService.getClusterCategories(clusterId)
  ])
}
</script>

<style scoped>

</style>
