<template>
  <div>

    <v-container>
      <v-row>
        <v-col cols="12">
          <img style="width: 300px" src="https://www.kh-odw.de/fileadmin/platzhirsche/mitglieder/2/25471/Logo_KH_2019.png">
        </v-col>
        <v-col cols="8"></v-col>
        <v-col cols="4">
          <v-text-field
              label="Freie Suche"
              prepend-icon="mdi-magnify"
              append-icon="mdi-close"
              v-model="search"
              @click:append="search=''"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-row>
            <v-col cols="12">
              <google-maps :companies="companies" :lat="49.656791" :lng="8.9897185" :zoom="11"/>
            </v-col>
            <v-col cols="12">
              <p class="text-h6">Kalender</p>
              <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat</div>
              <v-btn class="mt-2" color="primary" @click="$refs.calendarDialog.show()">Kalender anzeigen</v-btn>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col :key="post.id" cols="4" v-for="post in news">
                  <v-card
                      max-width="344"
                      outlined
                  >
                    <v-list-item three-line>
                      <v-list-item-content class="pa-0"
                      >
                        <v-img
                            :src="getUrl(post.image)"
                            alt="Nachrichtenbild"
                            max-width="205"
                        />
                        <v-list-item-title>
                          {{ post.title }}
                        </v-list-item-title>
                        <div class="body-2"></div>
                        {{ post.content }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>

                </v-col>
              </v-row>
            </v-col>

          </v-row>

        </v-col>
        <v-col cols="4">
          <v-tabs v-model="category">
            <v-tab :key="category.id" v-for="category in categories" :href="'#'+category.id">
              {{category.name}}
            </v-tab>
            <v-tab-item :key="category.id" v-for="category in categories" :value="category.id" class="tabItem">
              <v-card
                  max-width="344"
                  outlined
                  v-for="post in filteredPosts"
                  :key="post.id"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ post.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{post.excerpt}}</v-list-item-subtitle>
                    <div class="body-2"> {{ post.content }}</div>
                  </v-list-item-content>
                  <v-list-item-avatar
                      tile
                      size="80"
                      color="grey"
                  >
                    <v-img
                        :src="getUrl(post.image)"
                        class="post"
                        alt="Beitragsbild"
                    />
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-btn
                      outlined
                      rounded
                      text
                      @click="$refs.postDialog.show(post)"
                  >
                    Mehr
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
    <post-dialog ref="postDialog"/>
    <calendar-dialog ref="calendarDialog"/>
  </div>
</template>

<script>
import APIService from "@/services/APIService";
import PostDialog from "@/components/PostDialog";
import {shuffle} from "lodash";
import GoogleMaps from "@/components/GoogleMaps";
import CalendarDialog from "@/components/CalendarDialog";

const CLUSTER_ID = "61f7dc1826b89900302bb11f"

export default {
  name: 'ClusterOdenwald',
  components: {GoogleMaps, PostDialog, CalendarDialog},
  data:() => {
    return {
      category: 'feed',
      search: '',
      categories: [],
      posts: [],
      news: [],
      companies: [],
    }
  },
  methods: {
    getUrl(image) {
      if(!image) {
        return 'https://picsum.photos/500/300?image=1'
      }
      return  image.startsWith('http') ? image : `https://picsum.photos/500/300?image=${parseInt(image)}`
    }
  },
  computed: {
    filteredPosts() {
      console.log('posts', this.category)
      const search = this.search.toLowerCase()
      const regEx = new RegExp( search , 'i')

      console.log('Posts', this.posts)
      return this.posts.filter(post => {
        return regEx.test(post.title) || regEx.test(post.excerpt) || regEx.test(post.content)
      })
    }
  },
  watch: {
    category(){
      APIService.getClusterPosts(CLUSTER_ID, this.category).then((posts => this.posts = posts))
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      APIService.getPostCategories(),
      APIService.getClusterPosts(CLUSTER_ID,'feed'),
      APIService.getClusterPosts(CLUSTER_ID, 'news'),
      APIService.getClusterMembers(CLUSTER_ID),
    ]).then(result => {
      console.log("then", result)
      next(vm => {
        vm.categories = result[0].filter(category => category.id !== 'news')
        vm.posts = result[1]
        vm.news = shuffle(result[2]).slice(0,3)
        vm.companies = result[3]
      })
    })
  }
}
</script>
<style lang="scss">
.tabItem {
  max-height: 500px;
  overflow-y: auto;
}
</style>
