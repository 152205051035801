import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {vuetify, i18n} from './plugins/vuetify'
import './assets/css/main.css';
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/300-italic.css"
import "@fontsource/open-sans/400-italic.css"
import "@fontsource/open-sans/700-italic.css"
import VueExpandableImage from 'vue-expandable-image'
import './filters'

export const eventBus = new Vue()

Vue.config.productionTip = false
Vue.use(VueExpandableImage)
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
