<template>
  <div>
    <h1>Tags<span v-if="cluster"> ({{cluster.name}})</span></h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createTag"
            >
              <v-icon left>mdi-plus</v-icon>
              Tag
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="tags"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
          :hide-default-footer="tags.length < 5"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editTag(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteTag(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <tag-edit-dialog ref="tagEditDialog" @updated="tagUpdated" @saved="tagSaved"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import TagEditDialog from '@/components/admin/TagEditDialog'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  name: "TagList",
  components: {
    TagEditDialog,
    ConfirmDialog
  },
  props: ['clusterId'],
  data() {
    return {
      cluster: {},
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
          width: 500,
        },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      search: '',
      tags: []
    }
  },
  methods: {
    createTag() {
      this.$refs.tagEditDialog.show(undefined, this.clusterId)
    },
    editTag(item) {
      console.log("Item", item)
      this.$refs.tagEditDialog.show(item, this.clusterId)
    },
    deleteTag: function (tag) {
      this.$refs.confirmDelete.show({
        title: 'Tag entfernen',
        text: `Wollen sie den Tag <b>${tag.name}</b> entfernen?`,
        confirm: 'entfernen'
      }).then(() => {
        APIService.deleteTag(tag.id).then(() => {
          this.tags = this.tags.filter((p) => p.id !== tag.id)
          console.log('Item deleted')
        })
      })
    },
    tagSaved(tag) {
      console.log("Saved", tag)
      this.tags = [...this.tags, tag]
    },
    tagUpdated(tag) {
      console.log("updated", tag)
      this.tags = this.tags.map((p) => tag.id === p.id ? tag : p)
    },
  },
  async beforeRouteEnter(to, from ,next) {
    const clusterId = to.params.clusterId
    let cluster, tags
    if(clusterId) {
      [cluster, tags] = await fetchAll(clusterId)

    } else {
      tags = await APIService.getTags()
    }
    next(vm => {
      vm.cluster  = cluster
      vm.tags  = tags
    })
  },
  async beforeRouteUpdate(to, from, next) {
    const clusterId = to.params.clusterId
    const [cluster, tags] = await fetchAll(clusterId)
    this.cluster = cluster
    this.tags = tags
    next()
  }
}
async function fetchAll(clusterId) {
  return Promise.all( [
    APIService.getCluster(clusterId),
    APIService.getClusterTags(clusterId)
  ])
}
</script>

<style scoped>

</style>
