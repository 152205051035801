<template>
  <div>
    <post
        :max-width="maxWidth"
        outlined
        v-for="post in posts"
        :key="post.id"
        :post=post
        @click:company="companyClicked($event)"
        @viewPost="viewPost"
        :is="postType(post)"
        @highlight="highlight"
    />
    <post-dialog ref="postDialog"/>
  </div>
</template>

<script>
import Post from "@/components/Post";
import Event from "@/components/Event";
import PostDialog from "@/components/PostDialog";
import APIService from "@/services/APIService";

const POST_COMPONENT_NAMES = {
  message: "Post",
  event: "Event",

}

export default {
  name: "Posts",
  components: {Post, Event, PostDialog},
  props: {
    posts: {
      required: true
    },
    maxWidth: {
      required: false,
      default: '500'
    }
  },
  methods: {
    highlight(post) {
      this.$emit('highlight', post)
    },
    postType(item) {
      return POST_COMPONENT_NAMES[item.type]
    },
    async viewPost(post) {
      const _post = await APIService.getPost(post.id)
      this.$refs.postDialog.show(_post, post.userId.companyId)
    },
    companyClicked(event) {
      console.log("Event", event)
      this.$emit('click:company', event)
    }
  }
}
</script>

<style scoped>

</style>