<template>
  <v-row>
    <v-col v-if="company.info.storeWindowImage1" cols="6">
      <expandable-image
          class="image"
          :src="getFileUrl(company.info.storeWindowImage1.path)"
      />
    </v-col>
    <v-col v-if="company.info.storeWindowImage2" cols="6">
      <expandable-image
          class="image"
          :src="getFileUrl(company.info.storeWindowImage2.path)"
      />
    </v-col>
    <v-col v-if="company.info.storeWindowImage3" cols="6">
      <expandable-image
          class="image"
          alt="Bla"
          :src="getFileUrl(company.info.storeWindowImage3.path)"
      />
    </v-col>
    <v-col v-if="company.info.storeWindowImage4" cols="6">
      <expandable-image
          class="image"
          :src="getFileUrl(company.info.storeWindowImage4.path)"
      />
    </v-col>
  </v-row>
</template>

<script>
import {getFileUrl} from "@/utils/common";

export default {
  name: "CompanyStoreWindow",
  props: ['company'],
  methods: {
    getFileUrl
  }
}
</script>

<style scoped>

</style>
