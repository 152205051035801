<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="4">
          <img style="width: 300px" src="https://www.rutesheim.de/site/Rutesheim-Internet-2021/resourceCached/10.3.4.1/img/logo.png">
        </v-col>
        <v-col cols="6" class="text-h4 font-weight-bold">Rutesheim live</v-col>
        <v-col cols="2">
          <login-dialog/>
          <register-unit-dialog :cluster-id="clusterId"/>
        </v-col>
        <v-col cols="8"></v-col>
        <v-col cols="4">
          <v-text-field
              label="Freie Suche"
              prepend-icon="mdi-magnify"
              append-icon="mdi-close"
              v-model="search"
              @click:append="search=''"
          />
          <div class="text-center">
            <v-menu
                v-model="filterMenu"
                :close-on-content-click="false"
                :nudge-width="400"
            >
              <template v-slot:activator="{ on, attrs }">
                <a
                    v-bind="attrs"
                    v-on="on"
                >
                  Filter
                </a>
              </template>
              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Filter</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <category-switch
                    v-model="selectedCategories"
                    :items="categories"
                />
                <v-spacer></v-spacer>
                <multi-switch
                    v-model="selectedTags"
                    :items="availableTags"
                    item-value="id"
                    item-text="name"
                    label="Tags"/>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                      text
                      @click="filterMenu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      color="primary"
                      text
                      @click="filterMenu = false"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu><a @click="removeAllFilters">x</a>
          </div>
          <div class="selected-filters">
            <ul class="categories">
              <li :key="category.id" v-for="category in selectedCategories">
                {{category.name}}<a @click="removeCategory(category)">x</a>
              </li>
            </ul>
            <ul class="tags">
              <li :key="tag.id" v-for="tag in selectedTags">
                {{tag.name}}<a @click="removeTag(tag)">x</a>
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <template v-if="$vuetify.breakpoint.lgAndUp">
        <v-col cols="4">
          <v-tabs v-model="type">
            <v-tab href="#all">Alle</v-tab>
            <v-tab href="#message">Posts</v-tab>
            <v-tab href="#event">Veranstaltungen</v-tab>
            <v-tab-item value="all" class="tabItem">
              <posts :posts="filteredPosts" @highlight="highlightEntry($event)" @click:company="showCompanyDetails"/>
            </v-tab-item>
            <v-tab-item value="message" class="tabItem">
              <posts :posts="filteredPosts" @highlight="highlightEntry($event)" @click:company="showCompanyDetails"/>
            </v-tab-item>
            <v-tab-item value="event" class="tabItem">
              Veranstaltungen
              <posts :posts="filteredPosts" @highlight="highlightEntry($event)" @click:company="showCompanyDetails"/>
            </v-tab-item>
          </v-tabs>
        </v-col>
        <v-col cols="8">
          <google-maps :companies="companiesWithPosts" :categories="categories" :highlighted="highlighted" @click:company="showCompanyDetails"/>
        </v-col>
        </template>
        <template v-else>
          <v-col cols="3">
              <v-btn small :color="type === 'all' ? 'primary' : ''"  @click="type='all'">Alle</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn small :color="type === 'message' ? 'primary' : ''" @click="type='message'">Posts</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn small :color="type === 'event' ? 'primary' : ''" @click="type='event'">Veranstaltungen</v-btn>
          </v-col>
          <v-col cols="12">
            <posts :posts="filteredPosts" @highlight="highlightEntry($event)" @click:company="showCompanyDetails"/>
          </v-col>
        </template>
      </v-row>
    </v-container>
    <div
        class="d-lg-none" style="position: fixed; right: 0; top: 75%; color: white; background-color: rgb(92,172,73)">
      <maps-dialog :companies="companiesWithPosts" :categories="categories" :highlighted="highlighted" @click:company="showCompanyDetails"/>
    </div>
    <div
        v-if="$vuetify.breakpoint.mdAndDown"
        v-show="showMap"
        style="position: fixed; left: 0; top: 0;right: 0; bottom: 0; background-color: rgb(255,255,255,0.9)"
    >
      <v-card>
        <v-card-title>
           Karte Rutesheim <v-spacer/> <v-btn @click="showMap=false">X</v-btn>
        </v-card-title>
      </v-card>
      <div style="background-color: green">

      </div>
      <google-maps v-show="showMap" :companies="companiesWithPosts" :categories="categories" :highlighted="highlighted" @click:company="showCompanyDetails"/>
    </div>
    <company-info-dialog ref="companyInfoDialog"/>
  </div>
</template>

<script>
import APIService from "@/services/APIService";
import GoogleMaps from "@/components/GoogleMaps";
import Posts from "@/components/Posts";
import CompanyInfoDialog from "@/components/CompanyInfoDialog";
import {dateSort, getFileUrl} from "@/utils/common";
import MultiSwitch from "@/components/common/MultiSwitch";
import RegisterUnitDialog from "@/components/dialogs/RegisterUnitDialog.vue";
import CategorySwitch from "@/components/common/CategorySwitch.vue";
import MapsDialog from "@/components/dialogs/MapsDialog.vue";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";
import {eventBus} from "@/main";

const CLUSTER_ID = "619abb7d85356a75f8ed263f"

export default {
  name: 'ClusterRutesheim',
  components: {
    CategorySwitch, MultiSwitch, GoogleMaps, MapsDialog, CompanyInfoDialog, Posts, LoginDialog, RegisterUnitDialog},
  data:() => {
    return {
      category: 'all',
      categories: [],
      clusterId: CLUSTER_ID,
      events: [],
      fav: undefined,
      selectedCategories: [],
      selectedTags: [],
      showMap: false,
      hints: false,
      highlighted: "",
      message: false,
      filterMenu: false,
      search: '',
      postCategories: [],
      posts: [],
      companies: [],
      availableTags: [],
      registerDialog: false,
      type: 'all',
      types: [{}],
      sv: [],
    }
  },
  methods: {
    getFileUrl,
    async showCompanyDetails(company) {
      console.log("showCompanyDetails", company)
      const _company = await APIService.getCompany(company.id)
      await this.$refs.companyInfoDialog.show(_company)
    },
    highlightEntry(entry) {
      console.log("Blas", entry?.userId?.companyId?.id)
      this.highlighted = entry?.userId?.companyId?.id
    },
    removeTag(tag) {
      console.log("Tag removed", tag)
      this.selectedTags = this.selectedTags.filter(t => tag.id !== t.id)
    },
    removeCategory(category) {
      console.log("Category removed", category)
      this.selectedCategories = this.selectedCategories.filter(c => category.id !== c.id)
    },
    removeAllFilters() {
      this.selectedCategories = []
      this.selectedTags = []
    }
  },
  computed: {
    filteredPosts() {
      console.log('posts', this.category)
      const search = this.search.toLowerCase()
      const regEx = new RegExp( search , 'i')

      console.log('Posts', this.posts)
      const result = this.posts
          .filter(post => this.type === 'all' || post.type === this.type)
          .filter(post => {
            return regEx.test(post.title) || regEx.test(post.excerpt) || regEx.test(post.content) || regEx.test(post.userId?.companyId?.name)
          })
         .filter(post => this.filteredCompanies.map(company => company.id).includes(post.userId?.companyId?.id))
          .sort(dateSort(this.type !== 'event' ? 'publishingDate' : 'startDate', this.type === 'event' ? 'asc' : 'desc'))

      return result
    },
    filteredCompanies() {
      let result = this.selectedCategories.length > 0 ? this.companies.filter(categoryFilter(this.selectedCategories)) : this.companies
      return this.selectedTags.length > 0 ? result.filter(tagFilter(this.selectedTags)) : result
    },
    companiesWithPosts() {
      return this.companies.filter(company => [...new Set(this.filteredPosts.map(post => post.userId && post.userId.companyId.id))].includes(company.id))
    },
    selectedTagObjects() {
       return this.selectedTags.map((id) => this.availableTags.find((tag) => tag.id === id))
    }
  },
  async beforeRouteEnter(to, from, next) {

    const [categories, tags, postCategories, posts, members] = await Promise.all([
      APIService.getClusterCategories(CLUSTER_ID),
      APIService.getClusterTags(CLUSTER_ID),
      APIService.getPostCategories(),
      APIService.getClusterPosts(CLUSTER_ID),
      APIService.getClusterMembers(CLUSTER_ID),
    ])
    next(vm => {
      vm.categories = categories
      vm.availableTags = tags
      vm.postCategories = postCategories.filter(category => category.type === 'company')
      console.log("Posts", posts)
      vm.posts = posts.filter(category => category.id !== 'news')
      vm.companies = members
    })
  },
  mounted() {
    const token = this.$route.query.token
    console.log("token", token)
     if(token) {
       eventBus.$emit('show-reset-password-dialog', token)
     }
  }
}
const categoryFilter = categories =>  company => categories.map(category => category.id).includes(company.primaryCategory) || categories.some(category => company.categories.includes(category.id))
const tagFilter = selectedTags => company => selectedTags.some(tag => company.tags?.includes(tag.id))

</script>
<style lang="scss">
.tabItem {
  max-height: 500px;
  overflow-y: auto;
}
.selected {
  background-color: #409130;
}
</style>
