<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="700"
        @click:outside="hide"
        :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card v-if="company">
        <v-card-title class="text-h5 grey lighten-2">
          <img style="width: 200px" src="https://www.rutesheim.de/site/Rutesheim-Internet-2021/resourceCached/10.3.4.1/img/logo.png">
          <v-spacer/>
          <v-btn icon @click="dialog=false"><v-icon>mdi-close-thick</v-icon></v-btn>
          {{ company.name }}
        </v-card-title>

        <v-card-text>
          <v-tabs v-if="hasStoreWindow || posts" v-model="tabs">
            <v-tab href="#infos">Infos</v-tab>
            <v-tab v-if="hasStoreWindow" href="#store-window">Schaufenster</v-tab>
            <v-tab  v-if="posts" href="#post-tab">Beiträge</v-tab>
            <v-tab-item value="infos">
              <company-infos :company="this.company"/>
            </v-tab-item>
            <v-tab-item v-if="hasStoreWindow" class="store-window" value="store-window">
              <company-store-window :company="this.company"/>
            </v-tab-item>
            <v-tab-item v-if="posts" class="post-tab" value="post-tab">
              <posts :posts="posts" max-width="600"/>
            </v-tab-item>
          </v-tabs>
          <company-infos v-else :company="this.company"/>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="hide"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CompanyInfos from "@/components/CompanyInfos";
import CompanyStoreWindow from "@/components/CompanyStoreWindow";
import APIService from "@/services/APIService";
import {format, parseISO} from "date-fns";
import {de} from "date-fns/locale";
import Posts from "@/components/Posts";

export default {
  name: "CompanyInfoDialog",
  components: {CompanyStoreWindow, CompanyInfos, Posts},
  data() {
    return {
      company: undefined,
      dialog: false,
      posts: [],
      tabs: 'infos'
    }
  },
  methods: {
    async show(company) {
      console.log("Company", company)
      this.posts = await APIService.getCompanyPosts(company.id)
      this.tabs = 'infos'
      this.company = company
      this.dialog = true
    },
    async hide() {
      this.company = undefined
      this.dialog = false
    },
    getImageUrl(post) {
      console.log("Image", post)
      const img = post.image

      return img ? img.startsWith('http') ? img : `https://picsum.photos/500/300?image=${parseInt(img)+10}` : ''
    },
    computedDateFormattedDatefns (date) {
      return date ? format(parseISO(date), 'EEEE, dd.MM.yyyy', {locale: de}) : ''
    },
  },
  computed: {
    hasStoreWindow() {
      const info = this.company.info
      return info.storeWindowImage1 || info.storeWindowImage2 || info.storeWindowImage3 || info.storeWindowImage4
    }
  }
}
</script>

<style scoped>
</style>
