<template>
  <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      hide-on-leave
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      v-model="showDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <a
          v-on="on"
          class="mx-2"
          v-bind="attrs"
      >Registieren</a>
    </template>
    <template v-if="showDialog">
      <div>
        <v-card>
          <v-card-title class="justify-center">
            <img style="width: 200px" src="https://www.rutesheim.de/site/Rutesheim-Internet-2021/resourceCached/10.3.4.1/img/logo.png">
            <v-spacer/>
            <v-btn icon @click="showDialog=false"><v-icon>mdi-close-thick</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <template v-if="!showResponse">
              <h2>Ihre Registrierung</h2>
              <div>
                Nur noch wenige Klicks für Ihren Rutesheim-Perouse-Live-Account.
                Bitte füllen Sie die nachfolgenden Felder vollständig aus.
              </div>
              <v-form ref="form" value>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Ihr Unternehmen, Verein, weitere ... *"
                        v-model="user.company.name"
                        prepend-icon="mdi-office-building"
                        :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Vorname"
                        prepend-icon="mdi-account-circle"
                        v-model="user.firstName"
                        :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Nachname"
                        prepend-icon="mdi-account-circle"
                        v-model="user.lastName"
                        :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Strasse" v-model="user.company.address.street" :rules="requiredRule" prepend-icon="mdi-map-marker"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Hausnummer" v-model="user.company.address.houseNumber" :rules="requiredRule" prepend-icon="mdi-map-marker"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="PLZ" v-model="user.company.address.zip" :rules="requiredRule" prepend-icon="mdi-map-marker"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Ort" v-model="user.company.address.city" :rules="requiredRule" prepend-icon="mdi-map-marker"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Telefon" v-model="user.company.contact.phone" prepend-icon="mdi-map-marker"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Website" v-model="user.company.contact.homepage"></v-text-field>
                  </v-col>
                  <div>
                    <h3>Ihre Zugangsdaten</h3>
                    Hier können Sie Ihre zukünftigen Zugangsdaten angeben:
                  </div>
                  <v-col cols="12">
                    <v-text-field
                        label="E-Mail"
                        prepend-icon="mdi-at"
                        v-model="user.email"
                        :rules="emailRules"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Password"
                        prepend-icon="mdi-lock"
                        :type="showLogin ? 'text' : 'password'"
                        :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showLogin=!showLogin"
                        v-model="user.password"
                        :rules="passwordRules"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Passwort-Wiederholung"
                        prepend-icon="mdi-lock"
                        :type="showLogin ? 'text' : 'password'"
                        :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showLogin=!showLogin"
                        v-model="passwordConfirmation"
                        :rules="passwordConfirmationRules"

                    />
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox v-model="confirmPrivacyPolicy"  :rules="privacyRule" label='Ich bin einverstanden, dass meine Daten für die Verwendungszwecke der Plattform "Rutesheim-Live" genutzt werden.'/>
                  </v-col>
                </v-row>
              </v-form>
            </template>
            <template v-else>
                <h4>Vielen Dank für Ihre Registrierung.</h4>
                <p>Wir freuen uns, dass Sie auch bei Rutesheim Live mitmachen!</p>


                <p>Zur Bestätigung Ihrer Angaben erhalten Sie nun noch ein Email - dann können Sie direkt mit der Eingabe Ihrer Daten starten.</p>



                <p>Damit Ihr Eintrag angezeigt wird, muss er von der Stadt Rutesheim noch freigegeben werden. Sobald dies passiert ist, erhalten Sie eine Nachricht von uns.</p>



                <p>
                  Herzliche Grüße<br>
                  Ihre Stadt Rutesheim<br><br>
                  Kontaktdaten:<br>
                  Elke Hammer<br>
                  Email: e.hammer@rutesheim.de<br>
                  Telefon:
                </p>
            </template>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <template v-if="!showResponse">
              <v-btn
                  color="info"
                  class="mx-auto px-4"
                  @click="doRegister"
              >
                Account erstellen
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                  color="info"
                  class="mx-auto px-4"
                  @click="showDialog=false"
              >
                Schließen
              </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </div>
    </template>
  </v-dialog>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import APIService from "@/services/APIService";
import {snackbar} from "@/utils/common";
export default {
  name: 'RegisterUnitDialog',
  props: {
    clusterId: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      user: {
        company: {
          address: {},
          contact: {},
        },
      },
      confirmPrivacyPolicy: false,
      userType: "company",
      showLogin: false,
      showDialog: false,
      showResponse: false,
      loginFail: false,
      loginFailMessage: '',
      passwordConfirmation: '',
      emailRules: [
        v => !!v || 'Eingabe erforderlich',
        v => /.+@.+\..+/.test(v) || 'Gültige Mail-Adresse erforderlich',
      ],
      requiredRule: [
        value => !!value || 'Eingabe erforderlich'
      ],
      privacyRule: [
        value => !!value || 'Sie müssen der Veinbarung zustimmen'
      ],
      passwordRules: [
        value => !!value || 'Eingabe erforderlich',
        value => (value  && value.length >= 8) || 'Das Passwort muss mindestens 8 Zeichen lang sein.',
        value => (value && !!value.match(/\d/) && !!value.match(/[a-zA-Z]/)) || 'Das Passwort muss mindestens einen Buchstaben und eine Zahl enthalten',
      ],
      passwordConfirmationRules: [
        value => {
          console.log("compare", this.user.password, value )
          return !this.user.password || value === this.user.password || 'Die eingegebenen Passwörter stimmen nicht überein'
        }
      ]
    }
  },
  methods: {
    ...mapMutations([]),
    ...mapGetters(['isLoggedIn']),
    ...mapActions(['login', 'logout']),
    doRegister() {
      console.log(this.user)
      if (!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        return
      }
      APIService.register( {...this.user, cluster: this.clusterId}).then(() => {
        this.showResponse = true
      }).catch(() => {
        snackbar.error('Bei der Registrierung ist ein Fehler aufgetreten. Bitte versuchen sie es später erneut.')
      })
    },
  },
  computed: {
    ...mapState[{
      refreshToken: (state) => state?.user?.tokens?.refresh
    }]
  },
}
</script>

<style scoped>

</style>
