<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Mitglied hinzufügen</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="clusterMemberForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="8">
                  <v-select v-model="selectedCompany"
                            :items="nonMembers"
                            item-text="name"
                            item-value="id"
                            label="Firma"
                            :rules="requiredRule('Firma')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Schließen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="addClusterMember"
          >
            {{ post.id ? 'Speichern' : 'Hinzufügen'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>
<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/admin/Snackbar'
import {format, parseISO} from "date-fns";
import {de} from "date-fns/locale";

const NEW_POST_TEMPLATE = {
  categoryId: 'feed',
    publishingDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
}
export default {
  name: 'AddClusterMemberDialog',
  components: { Snackbar, ConfirmDialog },
  data: () => ({
    categories: [],
    selectedCompany: null,
    nonMembers: [],
    clusterId: null,
    showDialog: false,
    post: NEW_POST_TEMPLATE,
    snackbarSuccess: {
      text: 'Mitglied erfolgreich hinzugefügt',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    menu: false,
  }),
  methods: {
    async show (clusterId, members) {
      this.clusterId = clusterId
      this.nonMembers = await this.getNonMembers(members)
      this.showDialog = true
    },
    close () {
      this.selectedCompany = null
      this.$refs.clusterMemberForm.resetValidation()
      this.showDialog = false
    },
    async addClusterMember() {
      await APIService.addClusterMember(this.clusterId, this.selectedCompany)
      this.$emit('input', this.selectedCompany)
      this.close()
    },
    async getNonMembers(members) {
      const memberIds = members.map(member => member.id)
      const companies = await APIService.getCompanies()
      return  companies.filter((company) => !memberIds.includes(company.id) )
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
  },
  computed: {
    computedDateFormattedDatefns () {
      return this.post.publishingDate ? format(parseISO(this.post.publishingDate), 'EEEE, dd.MM.yyyy', {locale: de}) : ''
    },
  },
  mounted() {
    APIService.getPostCategories().then((categories) => {
      this.categories = categories
    })
  }
}
</script>

<style scoped>

</style>
