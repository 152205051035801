<template>
  <div>

    <v-container>
      <v-row>
        <v-col cols="4">
          <img style="width: 300px" src="https://www.straubing.de/__/img/straubing_logo.jpg">
        </v-col>
        <v-col cols="8" class="text-h4 font-weight-bold">Straubing Live</v-col>
        <v-col cols="8"></v-col>
        <v-col cols="4">
          <v-text-field
              label="Freie Suche"
              prepend-icon="mdi-magnify"
              append-icon="mdi-close"
              v-model="search"
              @click:append="search=''"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-row>
            <v-col cols="12">
              <google-maps :companies="companies" :lat="48.8819801" :lng="12.569716" :zoom="12"/>
            </v-col>
            <v-col cols="12">
              <p class="text-h6">Straubing Kalender</p>
              <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat</div>
              <v-btn class="mt-2" color="primary" @click="$refs.calendarDialog.show()">Kalender anzeigen</v-btn>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col :key="post.id" cols="4" v-for="post in news">
                  <v-card
                      max-width="344"
                      outlined
                  >
                    <v-list-item three-line>
                      <v-list-item-content class="pa-0"
                      >
                        <v-img
                            :src="`https://picsum.photos/500/300?image=${parseInt(post.image)+10}`"
                            height="200px"
                        />
                        <v-list-item-title>
                          {{ post.title }}
                        </v-list-item-title>
                        <div class="body-2"></div>
                        {{ post.content }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>

                </v-col>
              </v-row>
            </v-col>

          </v-row>

        </v-col>
        <v-col cols="4">
          <v-tabs v-model="category">
            <v-tab :key="category.id" v-for="category in categories" :href="'#'+category.id">
              {{category.name}}
            </v-tab>
            <v-tab-item :key="category.id" v-for="category in categories" :value="category.id" class="tabItem">
              <v-card
                  max-width="344"
                  outlined
                  v-for="post in filteredPosts"
                  :key="post.id"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ post.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{post.excerpt}}</v-list-item-subtitle>
                    <div class="body-2"> {{ post.content }}</div>
                  </v-list-item-content>
                  <v-list-item-avatar
                      tile
                      size="80"
                      color="grey"
                  >
                    <img
                        :src="`https://picsum.photos/500/300?image=${parseInt(post.image)+10}`"
                        alt="xxxx"
                    >
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-btn
                      outlined
                      rounded
                      text
                      @click="$refs.postDialog.show(post)"
                  >
                    Mehr
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item class="tabItem">
              <v-card
                  max-width="344"
                  outlined
                  v-for="i in 6"
                  :key="i"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      OVERLINE
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Neuigkeit {{ i }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                      tile
                      size="80"
                      color="grey"
                  >
                    <img
                        :src="`https://picsum.photos/500/300?image=${i+10}`"
                        alt="John"
                    >
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-btn
                      outlined
                      rounded
                      text
                  >
                    Button
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item class="tabItem">
              <v-card
                  max-width="344"
                  outlined
                  v-for="i in 6"
                  :key="i"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      OVERLINE
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Sonstiges {{ i }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar
                      tile
                      size="80"
                      color="grey"
                  >
                    <img
                        :src="`https://picsum.photos/500/300?image=${i+10}`"
                        alt="John"
                    >
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-btn
                      outlined
                      rounded
                      text
                  >
                    Button
                  </v-btn>
                </v-card-actions>
              </v-card>

            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
    <post-dialog ref="postDialog"/>
    <calendar-dialog ref="calendarDialog"/>
  </div>
</template>

<script>
import APIService from "@/services/APIService";
import PostDialog from "@/components/PostDialog";
import {shuffle} from "lodash";
import GoogleMaps from "@/components/GoogleMaps";
import CalendarDialog from "@/components/CalendarDialog";

export default {
  name: 'ClusterStraubing',
  components: {GoogleMaps, PostDialog, CalendarDialog},
  data:() => {
    return {
      category: 'feed',
      search: '',
      categories: [],
      posts: [],
      news: [],
      companies: []
    }
  },
  methods: {
  },
  computed: {
    filteredPosts() {
      console.log('posts', this.category)
      const search = this.search.toLowerCase()
      const regEx = new RegExp( search , 'i')

      console.log('Posts', this.posts)
      return this.posts.filter(post => {
        return regEx.test(post.title) || regEx.test(post.excerpt) || regEx.test(post.content)
      })
    }
  },
  watch: {
    category(){
      APIService.getClusterPosts("61b719b48b9d3a0343dcd464", this.category).then((posts => this.posts = posts))
    }
  },
  beforeRouteEnter(to, from, next) {
    const clusterId = "61b719b48b9d3a0343dcd464"
    Promise.all([
      APIService.getPostCategories(),
      APIService.getClusterPosts(clusterId,'feed'),
      APIService.getClusterPosts(clusterId, 'news'),
      APIService.getClusterMembers(clusterId),
    ]).then(result => {
      console.log("then", result)
      next(vm => {
        vm.categories = result[0].filter(category => category.id !== 'news')
        vm.posts = result[1]
        vm.news = shuffle(result[2]).slice(0,3)
        vm.companies = result[3]
      })
    })
  }
}
</script>
<style lang="scss">
.tabItem {
  max-height: 500px;
  overflow-y: auto;
}
</style>
