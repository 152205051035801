import Vue from 'vue'
import VueRouter from 'vue-router'
import Showcase from "@/views/admin/Showcase";
import Calendar from "@/views/admin/Calendar";
import Login from "@/views/Login";
import UserList from "@/views/admin/UserList";
import ClusterList from "@/views/admin/ClusterList";
import NewsList from "@/views/admin/NewsList";
import ClusterMembers from "@/views/admin/ClusterMembers";
import NestedList from "@/layouts/NestedList";
import Rutesheim from "@/views/clusters/Rutesheim";
import KHS from "@/views/clusters/KHS";
import Straubing from "@/views/clusters/Straubing";
import KHSOdenwald from "@/views/clusters/KHS-Odenwald";
import TagList from "@/views/admin/TagList";
import Register from "@/views/Register";
import VerifyEmail from "@/views/VerifyEmail";
import CategoryList from "@/views/admin/CategoryList";
import UserProfile from "@/views/admin/UserProfile";
import Mobile from "@/views/clusters/Mobile";
import ClusterJoinRequests from "@/views/admin/ClusterJoinRequests.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/mobile',
    component: Mobile
  },
  {
    path: '/rutesheim',
    name: 'rutesheim',
    component: Rutesheim
  },
  {
    path: '/straubing',
    name: 'straubing',
    component: Straubing
  },
  {
    path: '/khs',
    name: 'khs',
    component: KHS
  },
  {
    path: '/handwerk-odenwald',
    name: 'handwerk-odenwald',
    component: KHSOdenwald
  },
  {
    path: '/nestedList',
    name: 'nestedList',
    component: NestedList
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/verifyEmail',
    name: 'verifyEmail',
    component: VerifyEmail
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "adminLayout" */ '../layouts/AdminLayout'),
    children: [
      {path: 'users', name: 'user', component: UserList},
      {path: 'categories', name: 'categories', component: CategoryList},
      {path: 'tags', name: 'tags', component: TagList},
      {path: 'categories', name: 'tags', component: CategoryList},
      {path: 'clusters', name: 'clusters', component: ClusterList},
      {
        path: 'clusters/:clusterId/members',
        name: 'clusterMembers',
        component: ClusterMembers,
        props: true,
      },
      {path: 'clusters/:clusterId/news', name: 'news', component: NewsList, props: true},
      {path: 'clusters/:clusterId/tags', name: 'clusterTags', component: TagList, props: true},
      {path: 'clusters/:clusterId/categories', name: 'clusterCategories', component: CategoryList, props: true},
      {path: 'clusters/:clusterId/joinRequests', name: 'joinRequests', component: ClusterJoinRequests, props: true},

      {
        path: 'profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "adminLayout" */ '@/views/admin/Profile'),
      },
      {path: 'userProfile', name: 'userProfile', component: UserProfile},
      {
        path: 'posts',
        name: 'posts',
        component: () => import(/* webpackChunkName: "adminLayout" */ '@/views/admin/PostList'),
      },
      {
        path: 'events',
        name: 'events',
        component: () => import(/* webpackChunkName: "adminLayout" */ '@/views/admin/EventList'),
      },
      {path: 'showcase', name: 'showcase', component: Showcase},
      {path: 'calendar', name: 'calendar', component: Calendar}
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
