<template>
  <div>
    <h1 v-if="cluster">Mitgliedsanfragen({{cluster.name}})</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="joinRequests"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
          :hide-default-footer="joinRequests.length < 5"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              color="primary"
              @click="acceptRequest(item)"
          >
            mdi-thumb-up-outline
          </v-icon>
          <v-icon
              class="ml-3"
              small
              color="primary"
              @click="declineRequest(item)"
          >
            mdi-thumb-down-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import APIService from "@/services/APIService";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
export default {
  name: "ClusterJoinRequests",
  components: { ConfirmDialog },
  props: ['clusterId'],
  data() {
    return {
      joinRequests: [],
      cluster: null,
      search: '',
      headers: [
        {
          text: 'Firmenname',
          align: 'start',
          sortable: true,
          value: 'company.name'
        },
        { text: 'Datum', value: 'createdAt' },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
    }
  },
  methods: {
    async acceptRequest(request) {
      this.$refs.confirmDelete.show({
        title: 'Anfrage annehmen',
        text: `Wollen sie die Mitgliedsanfrage von ${request.company.name} annehmen?`,
        confirm: 'annehmen'
      }).then(async () => {
        this.joinRequests = await APIService.acceptClusterJoinRequest(this.clusterId, request.company.id)
      })
    },
    async declineRequest(request) {
      this.$refs.confirmDelete.show({
        title: 'Anfrage ablehnen',
        text: `Wollen sie Mitgliedsanfrage von ${request.company.name} ablehnen?`,
        confirm: 'ablehnen'
      }).then(async () => {
        this.joinRequests = await APIService.declineClusterJoinRequest(this.clusterId, request.company.id)
      })
    },
  },
  async beforeRouteEnter(to, from ,next) {
    const clusterId = to.params.clusterId

    const [cluster, joinRequests] = await fetchAll(clusterId)
    console.log("xxx", cluster, joinRequests)
    next(vm => {
      vm.cluster = cluster
      vm.joinRequests = joinRequests
    })
  },
  async beforeRouteUpdate(to, from, next) {
    const clusterId = to.params.clusterId
    const [cluster, joinRequests] = await fetchAll(clusterId)
    this.cluster = cluster
    this.joinRequests = joinRequests
    next()
  }
}
async function fetchAll(clusterId) {
  return Promise.all( [
    APIService.getCluster(clusterId),
    APIService.getClusterJoinRequests(clusterId)
  ])
}
</script>

<style scoped>
</style>
