<template>
  <div>
    <v-card width="400px" class="mt-5 mx-auto">
      <v-card-title>
        <h1>Registrieren</h1>
        <header>
          <h3>Ihre Registrierung</h3>
          Nur noch wenige Klicks für Ihren Rutesheim-Perouse-Live-Account.
          Bitte füllen Sie die nachfolgenden Felder vollständig aus.
        </header>
      </v-card-title>
      <v-card-text>
        <v-form value>
          <v-text-field
              label="Vorname"
              prepend-icon="mdi-account-circle"
              v-model="userData.firstName"
          />
          <v-text-field
              label="Nachname"
              prepend-icon="mdi-account-circle"
              v-model="userData.lastName"
          />
          <v-text-field
              label="E-Mail"
              prepend-icon="mdi-account-circle"
              v-model="userData.email"
          />
          <v-text-field
              label="Password"
              prepend-icon="mdi-lock"
              :type="showLogin ? 'text' : 'password'"
              :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showLogin=!showLogin"
              v-model="userData.password"
              :rules="passwordRules"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
            color="info"
            class="mx-auto px-4"
            @click="doLogin"
        >
          Registrieren
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import APIService from "@/services/APIService";
import {snackbar} from "@/utils/common";
export default {
  name: 'Login',
  data () {
    return {
      userData: {},
      showLogin: false,
      loginFail: false,
      loginFailMessage: '',
      passwordRules: [
        value => (!value || value  && value.length >= 8) || `Das Passwort muss mindestens 8 Zeichen lang sein.x`,
        value => (!value || value && !!value.match(/\d/) && !!value.match(/[a-zA-Z]/)) || 'Das Passwort muss mindestens einen Buchstaben und ein Zahl enthalten'
      ]
    }
  },
  methods: {
    ...mapMutations([]),
    ...mapGetters(['isLoggedIn']),
    ...mapActions(['login', 'logout']),
    doLogin () {
      APIService.register(this.userData).then(() => {
        this.$router.push({name: 'verifyEmail'})
      }).catch(() => {
        snackbar.error('Bei der Registrierung ist ein Fehler aufgetreten. Bitte versuchen sie es später erneut.')
      })
    }
  },
  computed: {
    ...mapState[{
      refreshToken: (state) => state?.user?.tokens?.refresh
    }]
  }
}
</script>

<style scoped>

</style>
