import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueI18n from 'vue-i18n'
import de from 'vuetify/lib/locale/de';

Vue.use(Vuetify);
Vue.use(VueI18n)

const messages = {
    de: {
        "Search": "Suche",
        $vuetify: de
    }
}

export const i18n = new VueI18n({
    locale: 'de',
    messages,
})

export const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#409130',
                secondary: '#b0bec5',
                anchor: '#FFFFFF',
            },
        },
    },
    lang: {
        locales: { de },
        current: 'de',
    },
});
