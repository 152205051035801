<template>
  <div>
    <div class="text-h5">Benutzer-Profil</div>
    <v-form>
      <image-input
          v-model="avatarImage"
          label="Avatar-Bild"
      />
      <v-btn :disabled="saveDisabled" color="primary" @click="saveProfile">Profil speichern</v-btn>
    </v-form>
  </div>
</template>

<script>
import APIService from "@/services/APIService";
import {mapState} from "vuex";
import ImageInput from "@/components/common/ImageInput";
import store from "@/store";

export default {
  name: "UserProfile",
  components: { ImageInput },
  data() {
    return {
      avatarImage: {},
      saveDisabled: false
    }
  },
  methods: {
    async saveProfile() {
      this.saveDisabled=true
      await APIService.updateUser({id: this.user.id, avatarImage: this.avatarImage})
      this.$store.commit('updateUser', {...this.user, avatarImage: this.avatarImage})
      this.saveDisabled=false
    },
  },
  computed: {
    ...mapState(['user']),
  },
  async beforeRouteEnter(to, from, next) {
    const user = store.state.user
    console.log("Before route enter user profile", user)
    next(vm => {
      vm.avatarImage = user.avatarImage
    })
  }
}
</script>

<style lang="scss" scoped>
form {
  width: 70%;
}
</style>
