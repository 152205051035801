<template>
  <v-card
      :max-width="maxWidth"
      outlined
      class="post"
      :class="post.type"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title>
          <p  v-if="post.userId.companyId" class="name mb-1">
            <a href="#" @click.prevent="$emit('click:company',post.userId.companyId)">
              {{post.userId.companyId.name}}
            </a>
          </p>
          <p class="date mb-1">veröffentlich am: {{post.publishingDate | formatLocaleDate}}</p>
          <p class="title">{{ post.title }}</p>
          <p class="start-date mb-1">Beginn: {{post.startDate | formatLocaleDateTime}}</p>
        </v-list-item-title>
        <v-list-item-subtitle>{{post.excerpt}}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar
          tile
          width="80px"
          height="auto"
          color="grey"
      >
        <img v-if="post.image" :src="getFileUrl(post.image.path)">
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
          outlined
          rounded
          text
          @click="viewPost"
      >
        Mehr
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {getFileUrl} from "@/utils/common";

export default {
  name: "PostCard",
  props: {
    post: {
      required: true
    },
    maxWidth: {
      required: false,
      default: 344
    }
  },
  methods: {
    getFileUrl,
    viewPost() {
      this.$emit('viewPost', this.post)
    }
  }
}
</script>

<style scoped>
p.date {
  color: rgba(90,90,90);
}
</style>
