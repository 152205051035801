<template>
  <div>
    <h1>Cluster</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createCluster"
            >
              <v-icon left>mdi-plus</v-icon>
              Cluster
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="clusters"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
          :hide-default-footer="clusters.length < 5"
      >
        <template v-slot:item.avatarImage="{ item }">
          <v-avatar
              size="36px"
          >
          <v-img :src="`https://i.pravatar.cc/150?img=${item.avatarImage}`"/>
          </v-avatar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              color="primary"
              class="mr-2"
              @click="editCluster(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              color="primary"
              small
              @click="deleteCluster(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <cluster-edit-dialog ref="clusterEditDialog"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import ClusterEditDialog from '@/components/admin/ClusterEditDialog'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  name: "ClusterList",
  components: {
    ClusterEditDialog,
    ConfirmDialog
  },
  data() {
    return {
      search: '',
      categories: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      clusters: []
    }
  },
  methods: {
    async createCluster () {
     const cluster = await this.$refs.clusterEditDialog.show()
      this.clusters = [ ...this.clusters, cluster ]
    },
    async editCluster (item) {
      const cluster = await this.$refs.clusterEditDialog.show(item)
      this.clusters = this.clusters.map((p) => cluster.id === p.id ? cluster : p)
    },
    async deleteCluster(cluster) {
      await this.$refs.confirmDelete.show({
        title: 'Cluster entfernen',
        text: `Wollen sie den Cluster <b>${cluster.name}</b> entfernen?`,
        confirm: 'entfernen'
      })
      await APIService.deleteCluster(cluster.id)
      this.clusters = this.clusters.filter((c) => c.id !== cluster.id)
    },
  },
  async beforeRouteEnter(to, from, next) {
    const clusters = await APIService.getClusters()
      next(vm => {
        vm.clusters = clusters
      })
  }
}
</script>

<style scoped>

</style>
