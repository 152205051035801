<template>
  <v-row>
    <v-col cols="8">
      <p class="mt-5 description" v-html="company.info.description"/>
      <div class="address">
        <span class="text-decoration-underline">Adresse</span>
        <p>
          {{company.address.street}} {{company.address.houseNumber}}<br>
          {{company.address.zip}} {{company.address.city}}
        </p>
      </div>
      <div class="contact">
        <span class="text-decoration-underline">Kontakt</span>
        <p>
          <template v-if="company.contact.lastName">
            {{company.contact.firstName}} {{company.contact.lastName}}<br>
          </template>
          {{company.contact.phone}}<br>
          {{company.contact.email}}<br><br>
          <a :href="company.contact.homepage">{{company.contact.homepage}}</a>
        </p>
      </div>
      <div class="opening-hours" v-if="company.info.openingHours">
        <span class="text-decoration-underline">Öffnungszeiten</span>
        <p v-html="company.info.openingHours"/>
      </div>
      <div class="social-media">
        <a
            class="mr-2"
            v-if="company.contact.facebook"
            :href="`https://www.facebook.com/${company.contact.facebook}`"
            target="_blank">
          <img class="social-media-logo" src="/f_logo_RGB-Blue_58.png" alt="facebook logo"/>
        </a>
        <a
            v-if="company.contact.instagram"
            :href="`https://www.instagram.com/${company.contact.instagram}`"
            target="_blank">
          <img class="social-media-logo" src="/Instagram_Glyph_Gradient_RGB.png" alt="Instagram logo"/>
        </a>
      </div>
    </v-col>
    <v-col cols="4">
      <img class="logo" v-if="company.logo" :src="getFileUrl(company.logo.path)">
    </v-col>
  </v-row>
</template>

<script>
import {getFileUrl} from "@/utils/common";

export default {
  name: "CompanyInfos",
  props: ['company'],
  methods: {
    getFileUrl
  }
}
</script>

<style scoped>
.logo {
  margin-top: 5px;
  max-width: 150px;
}
.social-media-logo {
  width: 30px;
}
::v-deep .description p {
  margin-bottom: 0;
}
::v-deep .opening-hours p {
  margin-bottom: 0;
}
</style>
