<template>
  <div>
    <v-card width="400px" class="mt-5 mx-auto">
      <v-card-title>
        <h1>Login</h1>
      </v-card-title>
      <v-card-text>
        <v-form value>
          <v-text-field
              label="E-Mail"
              prepend-icon="mdi-account-circle"
              v-model="email"
              @keydown.enter.exact.prevent="doLogin"
          />
          <v-text-field
              label="Password"
              prepend-icon="mdi-lock"
              :type="showLogin ? 'text' : 'password'"
              :append-icon="showLogin ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showLogin=!showLogin"
              v-model="password"
              @keydown.enter.exact.prevent="doLogin"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
            color="info"
            class="mx-auto px-4"
            @click="doLogin"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
        v-model="loginFail"
        color="error"
        multi-line="multi-line"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-alert-circle</v-icon>
        <v-layout column>
          <div>{{loginFailMessage}}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      showLogin: false,
      loginFail: false,
      loginFailMessage: ''
    }
  },
  methods: {
    ...mapMutations([]),
    ...mapGetters(['isLoggedIn']),
    ...mapActions(['login', 'logout']),
    doLogin () {
      console.log("Loggin called")
      this.login({email: this.email, password: this.password}).then(() => {
        this.$router.push({name: 'admin'})
      }).catch(error => {
        console.log("Login failed", error)
        this.logout()
        this.loginFailMessage = 'Falscher Benutzername oder Passwort.'
        this.loginFail = true
        this.password = ''
      })
    }
  },
  computed: {
    ...mapState[{
      refreshToken: (state) => state?.user?.tokens?.refresh
    }]
  }
}
</script>

<style scoped>

</style>
