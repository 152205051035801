import {eventBus} from "@/main";

function  getFileUrl(path) {
    return `${process.env.VUE_APP_ROOT_API}/content/${path}`
}
const dateSort  =  (propertyName,order='asc') => (a, b) => {
    if(order === 'desc') {
        const temp = a
        a = b
        b = temp
    }
    console.log("Order", order)
    return new Date(a[propertyName]) - new Date(b[propertyName]);
}
const isEmailValid = (email) => {
    return  /^[a-zA-Z0-9.!#$%&'*+äöüÄÖÜ/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-äöüÄÖÜ]+)*$/.test(email)
}
const snackbar = {
    success: (message) => {
        eventBus.$emit('snackbar-success', message)
    },
    error: (message) => {
        eventBus.$emit('snackbar-error', message)
    },

}
const categoryTypes =  [
    { label: "Post", id: "post" },
    { label: "Kalender", id: "event" },
    { label: "Firma", id: "company" },
    { label: "Öffentliche Hand", id: "public" },
    { label: "Verein", id: "association" },
]
export {
    dateSort,
    getFileUrl,
    isEmailValid,
    snackbar,
    categoryTypes
}