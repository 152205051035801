<template>
  <div>
    <h1>Benutzer</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createPost"
            >
              <v-icon left>mdi-plus</v-icon>
              Benutzer
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
          :hide-default-footer="users.length < 5"
      >
        <template v-slot:item.avatarImage="{ item }">
          <v-avatar v-if="!item.avatarImage"
              size="36px"
              color="blue"
          >
            <span  class="white--text">{{ item.firstName | uppercaseFirstLetter }}{{ item.lastName | uppercaseFirstLetter }}</span>
          </v-avatar>
          <v-avatar v-if="item.avatarImage"
                    size="36px"
          >
            <v-img v-if="item.avatarImage" :src="getFileUrl(item.avatarImage.path)"/>
          </v-avatar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editUser(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteUser(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <user-edit-dialog ref="userEditDialog" @updated="userUpdated" @saved="userSaved"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import UserEditDialog from '@/components/admin/UserEditDialog'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import { format, parseISO } from 'date-fns'
import { de } from 'date-fns/locale'
import { getFileUrl } from "@/utils/common";

export default {
  name: "UserList",
  components: {
    UserEditDialog,
    ConfirmDialog
  },
  data() {
    return {
      search: '',
      categories: [],
      headers: [
        {
          text: 'Vorname',
          align: 'start',
          sortable: true,
          value: 'firstName'
        },
        {
          text: 'Nachname',
          align: 'start',
          sortable: true,
          value: 'lastName'
        },
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'email'
        },

        { text: 'Rolle', value: 'role' },
        { text: 'Avatar', value: 'avatarImage' },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      users: []
    }
  },
  methods: {
    getFileUrl,
    createPost () {
      this.$refs.userEditDialog.show()
    },
    async editUser(user) {
      const _user = await APIService.getUser(user.id)
      this.$refs.userEditDialog.show(_user)
    },
    deleteUser: function (user) {
      this.$refs.confirmDelete.show({
        title: 'User entfernen',
        text: `Wollen sie den user <b>${user.firstName + " " + user.lastName}</b> entfernen?`,
        confirm: 'entfernen'
      }).then(() => {
        APIService.deleteUser(user.id).then(() => {
          this.users = this.users.filter((u) => u.id !== user.id)
          console.log('Item deleted')
        })
      })
    },
    userSaved(user) {
      console.log("Saved", user)
      this.users = [...this.users, user]
    },
    userUpdated(user) {
      console.log("updated", user)
      this.users = this.users.map((u) => user.id === u.id ? user : u)
    },
    computedDateFormattedDatefns (date) {
      return date ? format(parseISO(date), 'EEEE, dd.MM.yyyy', {locale: de}) : ''
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all(
        [
          APIService.getUsers(),
          //             APIService.getRoles(),

        ],
    ).then((data) => {
      next(vm => {
        vm.users = data[0]
      })
    })
  }
}
</script>

<style scoped>

</style>
