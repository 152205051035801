<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Cluster</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="clusterEditForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="cluster.name"
                      label="Cluster-Name"
                      required
                      :rules="requiredRule('Cluster-Name')"
                  ></v-text-field>
                  <v-autocomplete
                    :items="users"
                    v-model="cluster.manager"
                    :item-text="userName"
                    item-value="id"
                    outlined
                    chips
                    small-chips
                    label="Manager"
                    item
                    multiple
                    />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Schließen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveCluster"
          >
            {{ cluster.id ? 'Speichern' : 'Hinzufügen' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/Snackbar'
export default {
  name: 'ClusterEditDialog',
  components: { Snackbar, ConfirmDialog },
  data: () => ({
    showDialog: false,
    showPassword: false,
    cluster: {},
    users: [],
    snackbarSuccess: {
      text: 'Cluster erfolgreich gespeichert',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    resolve: null,
  }),
  methods: {
    async show (cluster) {
      this.cluster = { ...cluster }
      this.users = await APIService.getUsers()
      this.showDialog = true
      return new Promise((resolve) => { this.resolve = resolve })
    },
    close () {
      this.showDialog = false
      this.cluster = {}
      this.$refs.clusterEditForm.resetValidation()
    },
    async saveCluster () {
      if (!this.$refs.clusterEditForm.validate()) {
        return
      }
      if (this.cluster.id) {
        APIService.updateCluster(this.cluster).then((cluster) => {
          this.resolve(cluster)
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      } else {
        APIService.saveCluster(this.cluster).then((cluster) => {
          this.resolve(cluster)
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      }
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
    userName (user) {
      return `${user.firstName} ${user.lastName}(${user.email})`
    },

  },
}
</script>

<style scoped>
</style>
